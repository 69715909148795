<template>
  <!-- 手机版主页 -->
  <div v-if="device == 'mobile'" class="mobile">
    <div class="h-full-w" style="position: fixed; top: 0; z-index: 9;">
      <navbar></navbar>
    </div>
    <luo-carousel class="luo-carousel" :interval="5000" :autoplay="true" arrow="never" indicator-position="none" direction="vertical">
      
      <!-- <luo-carousel-item v-show="useDefault" >
        <el-image :src="defaultbanner" style="width: 100%; height: 100%" fit="cover"></el-image>
      </luo-carousel-item> -->
      <luo-carousel-item v-for="url in mbanner" :key="url">
        <el-image :src="cdnUrl + url" style="width: 100%; height: 100%" fit="cover"></el-image>
      </luo-carousel-item>
    </luo-carousel>

    <div class="footer">
      <index-footer></index-footer>
    </div>

  </div>
  <!-- 电脑版主页 -->
  <div v-else class="index-container">
    <div class="navbar">
      <navbar></navbar>
    </div>

    <luo-carousel :interval="5000" :autoplay="true" arrow="never" indicator-position="none" direction="vertical">
      
      <!-- <luo-carousel-item v-show="useDefault" >
        <el-image :src="defaultbanner" style="width: 100%; height: 100%" fit="cover"></el-image>
      </luo-carousel-item> -->
      <luo-carousel-item v-for="url in banner" :key="url">
        <el-image :src="cdnUrl + url" style="width: 100%; height: 100%" fit="cover"></el-image>
      </luo-carousel-item>
    </luo-carousel>

    <div class="footer">
      <index-footer></index-footer>
    </div>
  </div>
</template>

<script>
/**
 * 
 * @description 
 * @author Henson
 * @date 2021/03/06 10:32:55
 * @property 
 * @event 
 */
import navbar from '@/components/Menu.vue'
import luoCarousel from "@/components/carousel/src/main.vue";
import luoCarouselItem from "@/components/carousel/src/item.vue";
import IndexFooter from "@/components/IndexFooter.vue";

import { mapState } from 'vuex'

// import defaultImg from '@/assets/images/default.js'

export default {
  components: {
    navbar,
    luoCarousel,
    luoCarouselItem,
    IndexFooter,
  },
  data() {
    return {
      cdnUrl: this.$config.CDN_URL,
      // urls: [
      //   require("@/assets/images/index-bg1.png"),
      //   require("@/assets/images/index-bg2.png"),
      //   require("@/assets/images/index-bg3.png"),
      // ]
      // defaultbanner: defaultImg,
      useDefault: true,
    }
  },
  computed: {
    ...mapState({
      banner: state => state.menu.banner,
      mbanner: state => state.menu.mbanner
    }),
  },
  watch: {
  },
  created() {
    this.$http.get('/content/content/list').then(res => {
      // console.log(res)
    })

    setTimeout(() => {this.useDefault = false}, 8000)
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>
<style lang='scss' scoped>
.navbar {
  position: fixed;
  z-index: 9;
  width: 100%;
}

.index-container::v-deep .el-carousel {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}
.index-container ::v-deep .el-carousel__container {
  position: relative;
  z-index: -1;
  height: 100vh;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}


// .mobile .luo-carousel {
//   position: absolute;
//   // margin-top: 64px;
//   bottom: 162px;
//   width: 100vw;
//   height: calc(100vh - 226px);
// }
.mobile .luo-carousel {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mobile .luo-carousel::v-deep .el-carousel {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
}

.mobile .luo-carousel ::v-deep .el-carousel__container {
  position: relative;
  z-index: -1;
  height: 100vh;
}
</style>